import { createApp } from 'vue';

import AddressSearch from '@/components/AddressSearch.vue';
import Icon from '@/components/ui/Icon.vue';

function addressSearch(element) {
  const app = createApp(AddressSearch);

  app.component('Icon', Icon);

  app.config.globalProperties.ENP = window.ENP;
  app.config.globalProperties.gettext = window.gettext;
  app.config.globalProperties.interpolate = window.interpolate;

  app.mount(element);
}

window.ENP = window.ENP || {};
Object.assign(window.ENP, {
  addressSearch,
});
