<template>
  <FormGroup :field="field" :errors="errors">
    <Input
      v-model="zipCode"
      maxlength="4"
      inputmode="numeric"
      pattern="[0-9]*"
      autocomplete="postal-code"
    />

    <div v-if="location" class="mrgt">
      <Alert v-if="location.isAvailable" type="success">
        <div class="h5 mrgt0 text-success">
          {{ gettext('Commune desservie') }}
        </div>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="servicedText"></div>
      </Alert>
      <Alert v-else type="error">
        <div class="h5 mrgt0 text-error">
          {{ gettext('Commune non desservie') }}
        </div>
        {{
          gettext('Ce lieu n’est actuellement pas desservi par notre réseau.')
        }}
      </Alert>
    </div>
  </FormGroup>
</template>

<script setup>
import { computed, ref, watch } from 'vue';

import FormGroup from './forms/FormGroup.vue';
import Input from './forms/fields/Input.vue';
import Alert from './ui/Alert.vue';

import useCitySearch from '@/composables/useCitySearch';
import { formatList } from '@/helpers/utils';

const zipCode = ref(null);
const { search, location, errors } = useCitySearch();

watch(zipCode, (val) => {
  search(val).then(
    () => {},
    () => {}
  );
});

const field = computed(() => {
  return {
    label: gettext('Code postal'),
    name: 'zip-code',
    required: true,
  };
});

const servicedText = computed(() => {
  if (!location.value) return null;

  const companyUrl = ENP.CONFIG.routes.companies[location.value.companyName];
  const company = companyUrl
    ? `<a href="${companyUrl}">${location.value.companyFullname}</a>`
    : location.value.companyFullname;

  if (location.value.citiesAvailable.length > 1) {
    const cities = formatList(
      location.value.citiesAvailable.map((city) => city.cityName)
    );

    return interpolate(gettext('%s sont desservies par <strong>%s</strong>.'), [
      cities,
      company,
    ]);
  }

  return interpolate(gettext('%s est desservie par <strong>%s</strong>.'), [
    location.value.citiesAvailable[0].cityName,
    company,
  ]);
});
</script>
